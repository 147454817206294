<template>
  <b-container class="py-1">
    <b-card>
      <b-container>
        <b-row class="mb-2">
          <b-col
            cols="12"
            style="display: flex; flex-direction: column; justify-items: center; align-items: center"
          >
            <img :src="daikinLogo" class="warranty-logo" />
            <h2 class="mb-2 font-weight-bold text-center">
              Warranty Registration
            </h2>
          </b-col>
        </b-row>

        <validation-observer :slim="true" ref="warrantyValidation">
          <div>
            <div class="section-data">
              <h5>Email Verification</h5>
              <p style="font-weight:bold; color:red;">
                Note: Please enter your email to receive an OTP for email
                validation
              </p>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
                tag="div"
                class="form-line"
              >
                <label>Email <span style="color:red">(*)</span></label>

                <div class="form-validation-input">
                  <div class="form-validation-input-with-button">
                    <div style="padding-left:0">
                      <b-form-input
                        placeholder="Enter Email"
                        class="form-input-custom"
                        v-model="form.customerData.email"
                        @input="editText"
                        :state="errors.length > 0 ? false : null"
                        :disabled="emailTrue"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                    <div v-if="verified" style="align-content: center">
                      <b-button
                        class="button-otp"
                        variant="primary"
                        :disabled="clicked === true"
                        @click="sendOTP"
                        >Request OTP</b-button
                      >
                    </div>
                  </div>
                </div>
              </validation-provider>
              <div><p></p></div>

              <div v-if="verified" style="font-weight:bold;">
                If you did not received the OTP in 60 seconds, please click
                Request OTP button again. &nbsp;&nbsp; ( {{ countDown }} Sec )
              </div>
              <p></p>
              <validation-provider
                tag="div"
                name="otp"
                class="form-line"
                rules="digits:4"
                #default="{ errors }"
                v-if="verified"
              >
                <label v-if="verified">OTP</label>

                <div class="form-validation-input" v-if="verified">
                  <div class="form-validation-input-with-button">
                    <div style="padding-left:0">
                      <b-form-input
                        placeholder="Enter OTP"
                        v-model="form.otp"
                        :state="errors.length > 0 ? false : null"
                      ></b-form-input>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                    <div>
                      <b-button
                        class="button-otp"
                        variant="primary"
                        @click="verify"
                        >Submit OTP</b-button
                      >
                    </div>
                  </div>
                </div>
              </validation-provider>

              <validation-provider tag="div" class="form-line">
                <!-- <div style="width:74%; text-left:left;">
                
                <b-button style="margin: 1% 2% 2% 2%" @click="resend">resend</b-button>
                <b-button style="margin: 1% 0% 2% 2%" @click="verify">verify</b-button>
              </div> -->
              </validation-provider>
            </div>

            <div class="section-data">
              <h5>Registration Type</h5>
              <validation-provider
                #default="{ errors }"
                name="Registration Type"
                tag="div"
                rules="required"
                class="form-line"
              >
                <label
                  >Registration Type <span style="color:red">(*)</span></label
                >
                <div class="form-validation-input">
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form.registerType"
                    placeholder="select Registration Type"
                    :options="installationType"
                  >
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </div>

            <div class="section-data">
              <h5>Contact Detail</h5>
              <validation-provider
                #default="{ errors }"
                name="Name Last"
                rules="min:3|required"
                tag="div"
                class="form-line"
              >
                <label> Type <span style="color:red">(*)</span>: </label>

                <b-form-select
                  id="input-delivery-"
                  v-model="form.customerData.customerType"
                  :options="endCostumerTypes"
                  :state="errors.length > 0 ? false : null"
                  :disabled="pass"
                >
                </b-form-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Company Name"
                rules="min:3|required"
                tag="div"
                class="form-line"
                v-if="form.customerData.customerType === 'Company'"
              >
                <label>Company Name <span style="color:red">(*)</span></label>

                <div class="form-validation-input">
                  <b-form-input
                    placeholder="Enter Company Name"
                    v-model="form.customerData.companyName"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Salutation"
                rules="required"
                tag="div"
                class="form-line"
                v-if="form.customerData.customerType === 'Individual'"
              >
                <label>Salutation <span style="color:red">(*)</span></label>
                <b-form-select
                  :state="errors.length > 0 ? false : null"
                  v-model="form.customerData.salutation"
                  :options="salutationList"
                  :disabled="pass"
                >
                </b-form-select>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="min:1|required"
                tag="div"
                class="form-line"
                v-if="form.customerData.customerType === 'Individual'"
              >
                <label>First Name <span style="color:red">(*)</span></label>
                <div class="form-validation-input">
                  <b-form-input
                    placeholder="Enter First Name"
                    v-model="form.customerData.nameFirst"
                    :disabled="pass"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="min:1|required"
                tag="div"
                class="form-line"
                v-if="form.customerData.customerType === 'Individual'"
              >
                <label> Last Name <span style="color:red">(*)</span> </label>

                <div class="form-validation-input">
                  <b-form-input
                    placeholder="Enter Last Name"
                    v-model="form.customerData.nameLast"
                    :disabled="pass"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Contact Number"
                rules="required|min:8"
                tag="div"
                class="form-line"
              >
                <label>
                  Contact Number <span style="color:red">(*)</span>
                </label>
                <div class="form-validation-input">
                  <b-form-input
                    placeholder="Enter Contact Number"
                    v-model="form.customerData.phone"
                    :disabled="pass"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </div>

            <!-- <validation-provider
              #default="{ errors }"
              name="Delivery Date"
              rules="required"
              tag="div"
              class="form-line"
            >
              <label>
                Delivery Date
              </label>

              <div class="form-validation-input">
                <b-form-datepicker v-model="form.deliveryDate" :state="errors.length > 0 ? false:null"></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </validation-provider> -->

            <div class="section-data">
              <h5>Installation Address</h5>

              <p class="font-weight-bold">
                Note: For Singapore address only
              </p>

              <validation-provider
                #default="{ errors }"
                name="Postal Code"
                rules="min:3|required"
                tag="div"
                class="form-line"
              >
                <label>Postal Code <span style="color:red">(*)</span></label>

                <div class="form-validation-input">
                  <b-form-input
                    @input="addAddressByPostal"
                    maxLength="6"
                    debounce="500"
                    :disabled="pass"
                    v-model="form.customerData.postal"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Input postal code to autofill address"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Block / No"
                rules="required"
                tag="div"
                class="form-line"
              >
                <label> Block / No <span style="color:red">(*)</span> </label>
                <div class="form-validation-input" v-if="isBuildingNoArray">
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form.customerData.address"
                    :options="arrayBuildingNo"
                  >
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
                <div class="form-validation-input" v-else>
                  <b-form-input
                    :disabled="pass"
                    v-model="form.customerData.address"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Input postal to autofill"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Road Name"
                rules="required"
                tag="div"
                class="form-line"
              >
                <label> Road Name <span style="color:red">(*)</span> </label>

                <div class="form-validation-input" v-if="isStreetArray">
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form.customerData.address2"
                    :options="arrayStreet"
                  >
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
                <div class="form-validation-input" v-else>
                  <b-form-input
                    v-model="form.customerData.address2"
                    :disabled="pass"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Input postal to autofill"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Unit No"
                tag="div"
                class="form-line"
              >
                <label>Unit No</label>
                <div class="form-validation-input">
                  <b-form-input
                    v-model="form.customerData.address3"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Leave blank if not applicable "
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Building Name"
                rules=""
                tag="div"
                class="form-line"
              >
                <label>
                  Building Name
                </label>
                <div class="form-validation-input" v-if="isBuildingNameArray">
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form.customerData.buildingName"
                    :options="arrayBuildingName"
                  >
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
                <div class="form-validation-input" v-else>
                  <b-form-input
                    v-model="form.customerData.buildingName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Input building name"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Building Type"
                tag="div"
                class="form-line"
              >
                <label>Building type</label>
                <div class="form-validation-input">
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form.customerData.buildingType"
                    placeholder="select Building Type"
                    :options="buildingTypeOpt"
                  >
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </div>
            <!--
            <div class="form-line">
              <label>
                Building Name
              </label>

              <b-form-input v-model="form.customerData.buildingName" placeholder="Delivery Building Number"></b-form-input>
            </div> -->

            <div class="section-data">
              <h5>
                {{
                  form.registerType === "airpur"
                    ? "Purchase Info"
                    : "Installation Info"
                }}
              </h5>

              <validation-provider
                #default="{ errors }"
                name="Installation Date"
                rules="required"
                tag="div"
                class="form-line"
                v-if="form.registerType === 'aircon'"
              >
                <label
                  >Installation Date <span style="color:red">(*)</span></label
                >

                <div class="form-validation-input">
                  <b-form-datepicker
                    placeholder="Select installation date"
                    v-model="form.installationDate"
                    :max="max"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Installer Company Name"
                rules="required"
                tag="div"
                class="form-line"
                v-if="form.registerType === 'aircon'"
              >
                <label
                  >Installer Company Name
                  <span style="color:red">(*)</span></label
                >
                <div class="form-validation-input">
                  <b-form-input
                    v-model="form.installerData.installerName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Installer Company Name"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Purchase Date"
                rules="required"
                tag="div"
                class="form-line"
                v-if="form.registerType === 'airpur'"
              >
                <label>Purchase Date <span style="color:red">(*)</span></label>

                <div class="form-validation-input">
                  <b-form-datepicker
                    placeholder="Select purchase date"
                    v-model="form.purchaseDate"
                    :max="max"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </div>

            <div class="section-data">
              <h5>Promo Code</h5>

              <!-- <div class="form-line">
                <label class="font-weight-bold">P Code</label>
                <b-form-input v-model="form.pCode" placeholder="Leave blank unless you have valid P code number."></b-form-input>
                <span class="mx-1">(<a href="#" @click.prevent="promoIndex = 0">?</a>)</span>
              </div> -->

              <div class="form-line">
                <label class="font-weight-bold">Reference Code</label>
                <b-form-input
                  v-model="form.promoCode"
                  placeholder="Leave blank unless you have valid reference number."
                ></b-form-input>
                <span class="mx-1"
                  >(<a href="#" @click.prevent="promoIndex = 0">?</a>)</span
                >
              </div>

              <p class="mb-0">
                Exclusive extended warranty applicable for using Daikin HVAC
                Installation. Please click on the question mark for
                more information.
              </p>

              <CoolLightBox
                :items="[promoImage]"
                :index="promoIndex"
                @close="promoIndex = null"
              >
              </CoolLightBox>
              <div class="images-wrapper">
                <div
                  class="image"
                  @click="promoIndex = 0"
                  :style="{
                    backgroundImage:
                      'url(data:image/png;base64,' + promoImage + ')',
                  }"
                ></div>
              </div>

              <b-modal v-model="bannerShow" hide-footer>
                <img class="img-fluid" :src="promoImage" />
              </b-modal>
            </div>

            <div class="section-data">
              <h5>Invoice</h5>

              <!-- <validation-provider
                #default="{ errors }"
                name="Invoice Date"
                rules="required"
                tag="div"
                class="form-line"
              > -->
              <label>Invoice Date</label>

              <div class="form-validation-input">
                <b-form-datepicker
                  placeholder="Select Invoice date"
                  v-model="form.invoiceDate"
                  :max="max"
                ></b-form-datepicker>
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </div>
              <!-- </validation-provider> -->

              <!-- TODO: ADD upload filed -->
              <b-modal size="xl" hide-footer v-model="showImage">
                <div
                  class="py-2"
                  style="display: flex; justify-content: center; align-content: center;"
                >
                  <img
                    style="cursor: pointer; max-width: 1280px; max-height: 720px; height: auto; width: auto;"
                    @click="showImage = true"
                    v-if="selected"
                    :src="previewInvoice"
                  />
                </div>
              </b-modal>

              <!-- <validation-provider
                #default="{ errors }"
                name="Invoice File"
                rules="required"
                tag="div"
                class="form-line"
              > -->

              <label>Select Invoice (max size 2 MB)</label>

              <b-form-file
                id="id1"
                @change="uploadInvoiceImage()"
                v-model="selected"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept="image/jpeg, image/jpg, image/gif, image/gif"
              />

              <!-- </validation-provider>                      -->

              <div style="margin: 10px;">
                <div
                  style="display: flex; flex-direction: column; align-items: center; "
                >
                  <img
                    class="mt-1"
                    style="cursor: pointer;"
                    @click="showImage = true"
                    width="40%"
                    height="40%"
                    v-if="isValidImage"
                    :src="previewInvoice"
                  />

                  <div
                    v-else-if="isPdf"
                    style="position: relative; overflow: auto; width: 100%;"
                  >
                    <iframe
                      :src="previewPdf"
                      type="application/pdf"
                      width="100%"
                      height="450"
                    ></iframe>
                    <b-button class="align-center" @click="openPdfInNewTab"
                      >open in new tab
                    </b-button>
                  </div>
                </div>
                <p style="color: red; margin-top: 4px" v-if="isValidImage">
                  {{ isValidMessage }}
                </p>
              </div>
            </div>
          </div>
        </validation-observer>

        <div class="section-data">
          <h5>Product Entry</h5>

          <b-col cols="12">
            <b-row>
              <p class="font-weight-bold">
                Note: Barcode scanning works on mobile phones only. If it does
                not work for you, kindly select manual entry instead. For
                troubleshooting issues, call us at 6583 8888 .
                <!-- or email <a href="mailto:daikincms@daikin.com.sg">daikincms@daikin.com.sg</a> -->
              </p>
            </b-row>

            <div
              class="d-flex flex-row justify-content-center"
              style="gap: 8px"
            >
              <b-button
                variant="primary"
                @click="modalScan = true"
                class="d-flex flex-column align-items-center"
              >
                <b-icon
                  style="width: 40px; height: 40px;"
                  class="mb-1"
                  icon="search"
                ></b-icon>
                <div class="font-weight-bold text-center">Barcode Scan</div>
              </b-button>

              <b-button
                variant="primary"
                @click="modalSerial = true"
                class="d-flex flex-column align-items-center"
              >
                <b-icon
                  style="width: 40px; height: 40px;"
                  class="mb-1"
                  icon="pencil"
                ></b-icon>
                <div class="font-weight-bold text-center">Manual Entry</div>
              </b-button>
            </div>
          </b-col>
        </div>

        <div class="section-data">
          <h5>Product to be registered</h5>

          <b-row>
            <b-col cols="12">
              <p class="font-weight-bold"></p>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col cols="12" class="my-2">
                  <b-table
                    :items="backendItems"
                    :fields="fields"
                    responsive="sm"
                    show-empty
                    empty-text="No Product To Register"
                  >
                    <template #cell(actions)="row">
                      <b-button size="sm" @click="deleteItem(row)" class="mr-1">
                        Delete
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12">
              <p class="font-weight-bold">
                Note: <br />
                1. Kindly ensure information keyed in is correct. <br />
                2. Please remember to register for each fan coil unit. <br />
              </p>
            </b-col>
          </b-row>
        </div>

        <div class="section-data">
          <h5>Privacy policy</h5>

          <p>
            The information we collect on this page will be confidential and
            will not be shown anywhere on this website or shared with third
            parties without your explicit permission. For more information, read
            our
            <a target="_blank" href="https://www.daikin.com.sg/privacy"
              >privacy policy</a
            >
          </p>

          <b-form-checkbox
            id="checkbox-1"
            name="checkbox-1"
            v-model="agree"
            value="true"
            unchecked-value="false"
          >
            By checking this box, I give my consent to receive emails, SMSes or
            calls of advertising and information material on products and
            services offered by Daikin Airconditioning (Singapore) Pte Ltd.
          </b-form-checkbox>

          <p class="font-weight-bold mt-2">
            Please make sure that the email address you provided is valid.
          </p>

          <b-button
            variant="success"
            :disabled="onSubmit"
            @click="submitWarranty"
          >
            <template v-if="onSubmit">
              <div class="d-flex justify-content-center align-items-center">
                <b-spinner label="Loading..." class="mr-1"></b-spinner>
                Processing
              </div>
            </template>

            <template v-else>
              Submit
            </template>
          </b-button>
        </div>
      </b-container>
    </b-card>

    <b-modal
      v-model="modalScan"
      size="lg"
      title="Scan Barcode"
      @close="clearBarcode"
    >
      <b-container>
        <b-row>
          <b-col cols="12" v-if="!startScan">
            <p>Instructions</p>

            <img :src="barcodeImage" style="width: 100%; height: auto" />

            <ul style="list-style: decimal; padding: 0 0 0 10px;">
              <li>Click Start</li>
              <li>Please look barcode that has 14 digits code below it.</li>
              <li>If barcode is valid you will receive a success message</li>
              <li>Proceed to scan the rest of the codes</li>
              <li>Click OK to finish adding</li>
            </ul>

            <div class="my-3" style="display: flex; justify-content: center">
              <b-button variant="success" @click="startScan = true"
                >Start</b-button
              >
            </div>
          </b-col>

          <b-col cols="12" v-if="startScan">
            <!-- <div class="barcode-scanner">
              <v-quagga :onDetected="onDecode" :readerTypes="['code_39_reader']"/>
            </div> -->

            <!-- <div class="barcode-scanner">
              <v-quangga-image :onDetected="onDecode" :readerTypes="['code_39_reader']"/>
            </div> -->

            <!-- <zbar-scanner @decode="onDecodeZbar"/> -->

            <zxing-scanner @decode="(code) => (barcode = code)" />

            <b-form-input
              v-model="barcode"
              type="text"
              placeholder="Item Barcode"
              class="my-1 verifyButton"
              disabled
              style="text-color: #ffffff"
            ></b-form-input>

            <div style="display: flex; justify-content: center">
              <b-button
                @click="verifySerial"
                :disabled="barcode === null"
                variant="primary"
              >
                Add
              </b-button>
            </div>
          </b-col>

          <b-col cols="12" title="Manual Entry" v-if="startScan" class="mt-2">
            <div class="section-data">
              <h5>Product to be registered</h5>
              <b-table
                :fields="addItemFields"
                :show-empty="true"
                :items="backendItems"
                responsive="sm"
                empty-text="Add product to be registered"
              >
                <template #cell(actions)="row">
                  <b-button size="sm" @click="deleteItem(row)" class="mr-1">
                    Delete
                  </b-button>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <!-- <b-button class="mr-1" variant="success" @click="() => { addAllItems(); modalScan = false; startScan = false; }">
            Save
          </b-button>

          <b-button variant="danger" @click="() => { modalScan = false; startScan = false; }">
            Cancel
          </b-button> -->

          <b-button
            class="mr-1"
            variant="success"
            @click="
              () => {
                modalScan = false;
                startScan = false;
                barcode = null;
              }
            "
          >
            Ok
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="modalManual" size="lg" title="Manual Entry">
      <b-container>
        <b-row>
          <b-col cols="12" v-if="!startManual">
            <p>Instructions</p>

            <img :src="manualImage" style="width: 100%; height: auto" />
            <ul style="list-style: decimal; padding: 0 0 0 10px;">
              <li>Click Start</li>
              <li>Please look for 14 digits code below the barcode</li>
              <li>
                Key in the code into the input box and click on ADD button
              </li>
              <li>Proceed to add the rest of the codes</li>
              <li>Click OK to finish adding</li>
            </ul>

            <div class="my-3" style="display: flex; justify-content: center">
              <b-button variant="success" @click="startManual = true"
                >Start</b-button
              >
            </div>
          </b-col>

          <b-col cols="12" v-if="startManual">
            <div class="d-flex">
              <b-form-input
                v-model="barcode"
                maxLength="14"
                placeholder="Input Barcode"
              ></b-form-input>
              <b-button variant="success" class="ml-1" @click="verifySerial"
                >Add</b-button
              >
            </div>

            <p class="my-2">
              Key in 14 digit code into the input box exclude 2 asterisk *
            </p>
          </b-col>

          <b-col cols="12" v-if="startManual" class="mt-3">
            <b-table
              :fields="addItemFields"
              :items="backendItems"
              responsive="sm"
            >
              <template #cell(actions)="row">
                <b-button size="sm" @click="deleteItem(row)" class="mr-1">
                  Delete
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <!-- <b-button class="mr-1" variant="success" @click="() => { addAllItems(); modalManual = false; startManual = false; }">
            Save
          </b-button>

          <b-button variant="danger" @click="() => { modalManual = false; startManual = false; }">
            Cancel
          </b-button> -->

          <b-button
            class="mr-1"
            variant="success"
            @click="
              () => {
                modalManual = false;
                startManual = false;
                barcode = null;
              }
            "
          >
            Ok
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- modal serial -->
    <b-modal
      v-model="modalSerial"
      size="lg"
      title="Please Enter Model and Serial Number"
    >
      <b-container>
        <b-row>
          <b-col cols="12" v-if="!startSerial">
            <p>Instructions</p>

            <div class="d-flex overflow-auto">
              <img :src="imageSerial1" style="width: 100%; height: auto" />
              <img :src="imageSerial2" style="width: 100%; height: auto" />
            </div>

            <ul style="list-style: decimal; padding: 0 0 0 10px;">
              <li>Click Start</li>
              <li>Look for model and serial</li>
              <li>Key in code into input box and click on ADD button</li>
              <li>Proceed to add the rest of model and serial</li>
              <li>Click OK to finish adding</li>
            </ul>

            <div class="my-3" style="display: flex; justify-content: center">
              <b-button variant="success" @click="startSerial = true"
                >Start</b-button
              >
            </div>
          </b-col>

          <b-col cols="12" v-if="startSerial">
            <div
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <!-- <b-form-input v-model="barcode" placeholder="Input Item Barcode"></b-form-input> -->
              <b-form-input
                class="my-1"
                v-model="model"
                placeholder="Input Model #"
              ></b-form-input>

              <b-form-input
                class="my-1"
                v-model="itemSerial"
                placeholder="Input Serial #"
              ></b-form-input>
              <b-button variant="success" class="mt-1" @click="verifySerial"
                >Add</b-button
              >
            </div>
          </b-col>

          <b-col cols="12" v-if="startSerial" class="mt-3">
            <div class="section-data">
              <h5>Product to be registered</h5>
              <b-table
                :fields="addItemSerialFields"
                :items="backendItems"
                responsive="sm"
                :show-empty="true"
                empty-text="Add product to be registered"
              >
                <template #cell(actions)="row">
                  <b-button size="sm" @click="deleteItem(row)" class="mr-1">
                    Delete
                  </b-button>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <!-- <b-button class="mr-1" variant="success" @click="() => { addAllItems(); modalSerial = false; startSerial = false; }">
            Save
          </b-button>

          <b-button variant="danger" @click="() => { modalSerial = false; startSerial = false; }">
            Cancel
          </b-button> -->

          <b-button
            class="mr-lodash1"
            variant="success"
            @click="
              () => {
                modalSerial = false;
                startSerial = false; /** itemSerial = null; */
              }
            "
          >
            Ok
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- end modal serial -->
    <!-- trigger -->
  </b-container>
</template>

<script>
import axios from "@/axios";
import { QrcodeStream } from "vue-qrcode-reader";
import VueQuagga from "vue-quaggajs";
import Vue from "vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, digits } from "@validations";
import Quagga from "quagga";
import lodash from "lodash";
// import CustomQuangga from '../components/CustomQuangga.vue';
// import CustomQuanggaDemo from '../components/CustomQuanggaDemo.vue';
// import CustomQuanggaImage from '../components/CustomQuanggaImage.vue';
// import CustomQuanggaImage from '../components/CustomQuanggaImage.vue';
import ZxingVue from "../components/ZxingEdit.vue";
import { mapActions, mapState } from "vuex";

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

// import ZbarScanner from '../components/ZbarScanner.vue';
import adapter from "webrtc-adapter";

document.quangga = Quagga;

// Vue.use(VueQuagga)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CoolLightBox,
    // 'v-quagga': CustomQuanggaImage,
    // 'v-quagga': CustomQuanggaDemo,
    // 'v-quangga-image': CustomQuanggaImage,
    // 'zbar-scanner': ZbarScanner,
    "zxing-scanner": ZxingVue,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    return {
      countDown: 60,
      editing: false,
      clicked: false,
      verified: true,
      emailTrue: false,
      pass: true,
      max: maxDate,
      barcodeImage: require("@/assets/images/drcc/barcode.png"),
      manualImage: require("@/assets/images/drcc/manual.png"),
      daikinLogo: require("@/assets/images/drcc/daikin-logo.png"),
      onSubmit: false,
      previewInvoice: "",
      showImage: false,
      isValidImage: null,
      isValidMessage: "",
      form: {
        customerData: {
          nameFirst: "",
          nameLast: "",
          address: "",
          address2: "",
          address3: "",
          buildingName: "",
          buildingType: "",
          customerPhone: "",
          email: "",
          customerType: "Individual",
          companyName: "",
          salutation: "MR",
        },
        installerData: {
          installerName: "",
          installerAddress: "",
          installerPhone: "",
          installerEmail: "",
        },
        dealerData: {
          dealerName: "",
          dealerAddress: "",
          dealerEmail: "",
          dealerPhone: "",
        },
        deliveryDate: null,
        installationDate: "",
        invoiceDate: "",
        fileInvoice: null,
        purchaseDate: "",
        registerType: "",
        promoCode: "",
        // pCode:'',
        otp: "",
      },
      inputType: null,
      endCostumerTypes: [
        { value: "Company", text: "Company" },
        { value: "Individual", text: "Individual" },
      ],
      fields: [
        { key: "itemModel", label: "Model" },
        { key: "barcode", label: "Barcode" },
        { key: "actions", label: "Actions" },
      ],
      scanPrompt: false,
      barcode: null,
      model: null,
      items: [],
      modalManual: false,
      modalScan: false,
      startScan: false,
      startManual: false,
      addItemFields: [
        { key: "itemModel", label: "Model" },
        { key: "barcode", label: "Barcode" },
        // { key: 'status', label: 'Status' },
        { key: "actions", label: "Action" },
      ],
      addItemSerialFields: [
        { key: "itemModel", label: "Model" },
        { key: "barcode", label: "Barcode" },
        { key: "serial", label: "Serial #" },
        { key: "actions", label: "Action" },
      ],
      buildingTypeOpt: [
        { value: "", text: "Select Building Type", disabled: true },
        "HDB",
        "Condominium",
        "Private Housing",
        "Commercial Building",
      ],
      salutationList: [
        {
          text: "Mr",
          value: "MR",
        },
        {
          text: "Ms",
          value: "MS",
        },
      ],
      firstInit: false,
      backendItems: [],
      arrayBuildingNo: [],
      arrayStreet: [],
      arrayBuildingName: [],
      isBuildingNoArray: false,
      isStreetArray: false,
      isBuildingNameArray: false,
      installationType: [
        { value: "", text: "Select Registration Type", disabled: true },
        {
          text: "Air Conditioner",
          value: "aircon",
        },
        {
          text: "Air Purifier",
          value: "airpur",
        },
      ],
      agree: true,
      modalSerial: false,
      manualSerial: "",
      startSerial: false,
      itemSerial: "",
      imageSerial1: require("@/assets/images/drcc/serial1.png"),
      imageSerial2: require("@/assets/images/drcc/serial2.png"),
      bannerShow: false,
      promoImage: require("@/assets/images/drcc/DaikinPromo.png"),
      promoIndex: null,
      selected: null,
      image: "",
      isPdf: false,
    };
  },
  methods: {
    countdownTimer() {
      if (this.countDown > -1) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countdownTimer();
        }, 1000);
      }
      if (this.countDown == -1) {
        this.clicked = false;
        this.countDown = 60;
      }
    },
    editText() {
      if (this.editing) {
        this.countDown = 0;
      }
      this.clicked = false;
      this.editing = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    postImages() {
      // console.log(this.selected)
      const fd = new FormData();
      fd.append("image", this.selected, this.selected.name);
      // console.log({fd})
      axios
        .post("sales-and-purchase/drcc/upload-image/invoice", fd)
        .then((res) => {
          this.$bvToast.toast("Image Uploaded Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          // this.$store.dispatch("item/fetchImage");
          this.form.fileInvoice = res.data.data;

          // console.log("image RSP ", this.form.fileInvoice);
          // console.log(res);
        })
        .catch((err) => {
          console.log({ err });
          this.$bvToast.toast("Failed To Upload Image", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        });
    },
    async uploadInvoiceImage() {
      console.log("selected", this.selected);
      this.isPdf = false;

      const type = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/jpg",
        "application/pdf",
      ];
      this.isValidImage = false;
      this.isValidMessage = "";
      const file = document.querySelector("input[id=id1]").files[0];
      // console.log({file})
      if (!file || !file.name) {
        // No file selected
        return;
      }
      const reader = new FileReader();

      const readAsDataURL = () => {
        return new Promise((resolve) => {
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      };

      try {
        const result = await readAsDataURL();

        if (type.includes(file.type)) {
          if (file.type.startsWith("image/")) {
            this.previewInvoice = result;
            this.isValidImage = true;
          } else if (file.type === "application/pdf") {
            this.previewPdf = result;
            this.isPdf = true;
          }
        }
      } catch (error) {
        console.error("Error reading file:", error);
      }

      // let rawImg;
      // reader.onloadend = () => {
      //   rawImg = reader.result;
      //   this.previewInvoice = rawImg;
      // };
      // reader.readAsDataURL(file);
      // this.selected = file;

      if (this.selected.size > 1000000) {
        this.isValidImage = true;
        this.isValidMessage = "File size should be less than 2 MB";
      }

      if (!type.includes(this.selected.type)) {
        this.isValidImage = true;
        this.isValidMessage = "Input should be a format image";
      }
    },
    openPdfInNewTab() {
      // Optionally, open the PDF in a new tab
      const pdfWindow = window.open();
      pdfWindow.document.write(
        '<iframe width="100%" height="100%" src="' +
          this.previewPdf +
          '"></iframe>'
      );
    },
    addAddressByPostal: lodash.debounce(async function(postal) {
      if (postal.length != 6) {
        this.$bvToast.toast(`Postal not valid`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      var address;
      try {
        address = await this.getAddressByPostal(postal);
      } catch (err) {
        this.$bvToast.toast(
          `We are experiencing issue with OneMap API to retrieve your address, please enter your address manually.`,
          {
            title: "Failed",
            variant: "danger",
            solid: true,
          }
        );
        return;
      }
      console.info("address ", address);

      if (address.length <= 0) {
        this.$bvToast.toast("Postal " + postal + " has no address", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      var arrayStreet = [];
      var arrayBuildingName = [];
      var arrayBlockNumber = [];
      for (let postalData of address) {
        let { ROAD_NAME, BLK_NO, BUILDING } = postalData;
        arrayStreet.push(ROAD_NAME);
        arrayBuildingName.push(BUILDING);
        arrayBlockNumber.push(BLK_NO);
      }
      let newSetArrayStreet = [...new Set(arrayStreet)];
      let newSetArrayBuildingName = [...new Set(arrayBuildingName)];
      let newSetArrayBlockNumber = [...new Set(arrayBlockNumber)];

      if (newSetArrayBlockNumber.length > 1) {
        this.arrayBuildingNo = newSetArrayBlockNumber;
        this.isBuildingNoArray = true;
      } else {
        this.form.customerData.address = newSetArrayBlockNumber[0];
        this.isBuildingNoArray = false;
      }

      if (newSetArrayStreet.length > 1) {
        this.arrayStreet = newSetArrayStreet;
        this.isStreetArray = true;
      } else {
        this.form.customerData.address2 = newSetArrayStreet[0];
        this.isStreetArray = false;
      }

      if (newSetArrayBuildingName.length > 1) {
        this.arrayBuildingName = newSetArrayBuildingName;
        this.isBuildingNameArray = true;
      } else {
        this.form.customerData.buildingName = newSetArrayBuildingName[0];
        this.isBuildingNameArray = false;
      }

      this.$bvToast.toast(`Address found`, {
        title: "Success",
        variant: "success",
        solid: true,
      });
    }, 1000),
    autoFillAddress: lodash.debounce(function(a) {
      // console.log('this.itemDatabase', this.itemsDatabase)
      let arrayBuildingNo = [];
      let arrayStreet = [];
      let arrayBuildingName = [];

      axios
        .get(`sales-and-purchase/drcc/postal-info`, {
          params: {
            postal: a,
          },
        })
        .then((response) => {
          let data = response.data.data;
          for (let postalData of data) {
            let { postCode, buildingNo, street, buildingName } = postalData;
            arrayBuildingNo.push(buildingNo);
            arrayStreet.push(street);
            arrayBuildingName.push(buildingName);
          }

          let newSetArrayBuildingNo = [...new Set(arrayBuildingNo)];
          let newSetArrayStreet = [...new Set(arrayStreet)];
          let newSetArrayBuildingName = [...new Set(arrayBuildingName)];

          if (newSetArrayBuildingNo.length > 1) {
            this.arrayBuildingNo = newSetArrayBuildingNo;
            this.isBuildingNoArray = true;
          } else {
            this.form.customerData.address = newSetArrayBuildingNo[0];
            this.isBuildingNoArray = false;
          }

          if (newSetArrayStreet.length > 1) {
            this.arrayStreet = newSetArrayStreet;
            this.isStreetArray = true;
          } else {
            this.form.customerData.address2 = newSetArrayStreet[0];
            this.isStreetArray = false;
          }

          if (newSetArrayBuildingName.length > 1) {
            this.arrayBuildingName = newSetArrayBuildingName;
            this.isBuildingNameArray = true;
          } else {
            this.form.customerData.buildingName = newSetArrayBuildingName[0];
            this.isBuildingNameArray = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }, 500),
    onDecode({ codeResult: { code } }) {
      this.barcode = code;
    },
    onDecodeZbar(code) {
      this.barcode = code;
    },
    onDecodeQrCode(decodeString) {
      this.barcode = decodeString;
    },
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#007bff";
        ctx.strokeRect(x, y, width, height);
      }
    },
    addAllItems() {
      this.items = [...this.backendItems].map((i) => i.apiData);
    },
    clearBarcode() {
      this.barcode = null;
    },
    verifySerial() {
      // let postal = undefined;
      let barcode = undefined;
      let serial = undefined;
      let model = "";

      if (this.startSerial) {
        console.log("start manual");
        // Input By Serial And Model --> old
        model = this.model;
        serial = this.itemSerial;

        // Input Barcode only --> new
        // barcode = this.barcode
      } else {
        console.log("start barcode");
        barcode = this.barcode;
        // Input By Barcode (Scan Barcode Or Manual Entry)
        // console.log('Input By Barcode (Scan Or Manual)')
        // let localSerial = this.barcode;
        // localSerial = localSerial.replaceAll('*', '');

        // postal = localSerial.substr(0, 6).trim();
        // barcode = localSerial.substr(6, localSerial.length).trim();
        // model = this.model;
      }

      axios
        .post("sales-and-purchase/drcc/warranty/verify", {
          // postal: postal,
          barcode: barcode,
          model: model,
          serial: serial,
          registerType: this.form.registerType,
        })
        .then((response) => {
          let item = response.data.data;

          let itemDuplicate = this.backendItems.filter((x) => {
            if (x.barcode == item.barcode) {
              return true;
            }

            return false;
          });
          if (itemDuplicate.length > 0) {
            this.$bvToast.toast("Item Already Exists", {
              title: "failed",
              variant: "danger",
              solid: true,
            });

            this.barcode = null;
            this.model = null;
            this.itemSerial = null;
            return;
          }

          this.backendItems.push({
            itemModel: item.itemModel,
            status: "Valid",
            barcode: item.barcode,
            serial: item.serial,
            apiData: item,
          });

          // this.items.push(response.data.data);
          this.$bvToast.toast("Success Verify Item", {
            title: "Success",
            variant: "success",
            solid: true,
          });

          this.barcode = null;
          this.model = null;
          this.itemSerial = null;
        })
        .catch((e) => {
          this.$bvToast.toast(e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
            autoHideDelay: 15000,
            noAutoHide: true,
            toastClass: "custom-toast-text",
          });

          // this.barcode = null;
        });
    },
    async submitWarranty(asDraft = false) {
      if (this.onSubmit) {
        return;
      }
      let resImg;
      let resImgError = false;
      if (this.form.registerType === "") {
        // Prevent Not Selecting Registration Type
        this.$bvToast.toast("Please Select Registration Type", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }

      // if (this.selected == null ) {
      //   // Prevent file invoice from null
      //   this.$bvToast.toast('Invoice File is required', {
      //     title: "Failed",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   return;
      // }else {}
      if (this.selected !== null) {
        const fd = new FormData();
        fd.append("image", this.selected, this.selected.name);

        try {
          resImg = await axios.post(
            "sales-and-purchase/drcc/upload-image/invoice",
            fd
          );
          // console.log('res Axios',resImg)
          this.form.fileInvoice = resImg.data.data;
        } catch (error) {
          resImgError = true;
          console.error(error);
        }
      }

      let data = Object.assign(
        {},
        { ...this.form.customerData },
        { ...this.form.installerData },
        { items: [...this.backendItems].map((i) => i.apiData) },
        { installationDate: this.form.installationDate },
        { purchaseDate: this.form.purchaseDate },
        { policyAgreement: this.agree },
        { registerType: this.form.registerType },
        { invoiceDate: this.form.invoiceDate },
        { fileInvoice: this.form.fileInvoice }
      );

      if (this.form.promoCode.length !== 0) {
        // Prevent Send Empty Promo Code
        data = Object.assign(data, { promoCode: this.form.promoCode });
      }
      // if (this.form.pCode.length !== 0) {
      //   // Prevent Send Empty P Code
      //   data = Object.assign(data, { pCode: this.form.pCode })
      // }
      if (
        this.form.registerType === "airpur" &&
        this.form.purchaseDate === ""
      ) {
        this.$bvToast.toast("Purchase Date is required", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      if (
        this.form.registerType === "aircon" &&
        this.form.installationDate === ""
      ) {
        this.$bvToast.toast("Installation Date is required", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      // Prevent Backend Filled With Invalid Data
      if (this.form.registerType === "airpur") {
        // Air Purifier Don't Have Any Installer Info
        data.installerName = "";
        data.installationDate = "";
      } else {
        // Air Conditioner Don't Have Purchase Date
        data.purchaseDate = "";
      }
      // console.log(data);

      if (this.backendItems.length === 0) {
        // Prevent Zero Products Registration
        this.$bvToast.toast("No Products To Register", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.$refs.warrantyValidation
        .validate()
        .then((validationResult) => {
          if (validationResult) {
            this.onSubmit = true;

            if (resImgError == true) {
              this.onSubmit = false;
              return this.$bvToast.toast(
                "Upload Image Error. Please check the extension file type or file size.",
                {
                  title: "Failed",
                  variant: "danger",
                  solid: true,
                }
              );
            }

            axios
              .post(`sales-and-purchase/drcc/submit-warranty`, data)
              .then(async (response) => {
                this.$bvToast.toast("Successfully Process Paperless Warranty", {
                  title: "Success",
                  variant: "success",
                  solid: true,
                });

                this.onSubmit = false;
                this.items = [];
                await this.sleep(1500);
                this.$router.push({ name: "register-warranty-success" });
              })
              .catch((err) => {
                console.error({ err });
                if (err.request.status === 400) {
                  this.$bvToast.toast(
                    `${err.response.data.errors[0].message}`,
                    {
                      title: "Failed",
                      variant: "danger",
                      solid: true,
                      autoHideDelay: 6000,
                    }
                  );
                } else {
                  this.$bvToast.toast(`${err.response.data.message}`, {
                    title: "Failed",
                    variant: "danger",
                    solid: true,
                  });
                }

                this.onSubmit = false;
              });

            /**
            this.$bvToast.toast(`Thank you for your submission, you will receive an email confirmation within 7 working days.
Kindly call us at +65 6583 8888 if you have any queries.`, {
              title: "Success",
              variant: "success",
              solid: true,
            });
            **/
          } else {
            this.$bvToast.toast("Please fill the form first", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteItem(row) {
      // this.items.splice(row.index, 1);
      this.backendItems.splice(row.index, 1);
    },
    showPromoBanner() {
      this.bannerShow = true;
    },
    sendOTP() {
      axios
        .post("sales-and-purchase/drcc/send-otp", this.form.customerData)
        .then((response) => {
          this.$bvToast.toast(
            `OTP sent for email ${this.form.customerData.email}`,
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
          this.countdownTimer();
          this.clicked = true;
          this.editing = true;
        })
        .catch((e) => {
          console.log({ e });
          this.$bvToast.toast(`${e.response.data.errors[0].message}`, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        });
    },
    resend() {
      axios
        .post("sales-and-purchase/drcc/resend-otp", this.form.customerData)
        .then((response) => {
          this.$bvToast.toast(
            `request for resend OTP has been sent to ${this.form.customerData.email}`,
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
        })
        .catch((e) => {
          this.$bvToast.toast(
            `Failed to resend an OTP ${e.response.data.errors[0].message}`,
            {
              title: "Failed",
              variant: "danger",
              solid: true,
            }
          );
        });
    },
    verify() {
      let toSend = { email: this.form.customerData.email, otp: this.form.otp };
      axios
        .post("sales-and-purchase/drcc/verify-otp", toSend)
        .then((response) => {
          // console.log(response)
          this.$bvToast.toast(
            `Email is validated successfully. Please continue to fill up the warranty form.`,
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
          this.pass = false;
          this.form.otp = "";
          this.emailTrue = true;
          this.verified = false;
        })
        .catch((err) => {
          console.log({ err });
          if (err.request.status === 400) {
            this.$bvToast.toast(`${err.response.data.errors[0].message}`, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast(`${err.response.data.message}`, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
    ...mapActions({
      getUsersBy: "auth/getUsersBy",
      addNewUser: "auth/newUserRandomPW",
      getAddressByPostal: "mapAPI/getAddressByPostal",
    }),
  },
};
</script>

<style>
.custom-toast-text {
  font-size: 1.5rem; /* Increase the text size */
  font-weight: bold; /* Make the text bold */
}
.action-buttons {
  display: flex;
  flex-direction: row;
}

.action-buttons button {
  background-color: #e74c3c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 10px;
  margin: 10px 5px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 10px;
}

.action-buttons button:focus {
  outline: none;
}

.instructions {
  border-style: dashed;
  background-color: #f8c291;
  padding: 10px;
}

.area {
  border-style: double;
  background-color: #ffff;
  padding: 10px;
  border-radius: 10px;
}

.instructions ul {
  list-style: none;
  padding: 0;
}

.instructions ul li {
  font-weight: bold;
}

.serial-input {
  display: flex;
  flex-direction: row;
  border: 1px solid #9e9e9e;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
}

.serial-input button {
  background-color: #e55039;
  border: none;
  padding: 10px 15px;
  border-radius: 0 60px 60px 0;
  color: #ffffff;
  font-weight: bold;
}

.serial-input button:focus {
  outline: none;
}

.serial-input input {
  border: none;
  flex-grow: 1;
  padding: 0 10px;
}

.serial-input input:focus {
  outline: none;
}

.barcode-scanner {
  position: relative;
  width: 100%;
  /* height: 200px; */
  overflow: hidden;
}

.area {
  border-style: double;
  background-color: #ffff;
  padding: 10px;
  border-radius: 10px;
}

.viewport .scanner video {
  width: 320px;
  height: auto;
}

.viewport .scanner canvas {
  width: 320px;
  height: auto;
}

.form-line {
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
}

.form-line-otp {
  margin: 0 0 10px 0;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-validation-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-validation-input-otp {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.form-validation-input-with-button {
  display: flex;
  flex-direction: row;
  gap: 15px;

  div:first-child {
    flex-grow: 1 !important;
  }
}

.section-data {
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 0 0 20px 0;
}

.section-data h5 {
  position: absolute;
  top: -10px;
  left: 6px;
  background: white;
  padding: 0 10px;
}

.warranty-logo {
  width: 420px;
  height: auto;
  margin: 0 auto 15px auto;
}

@media only screen and (max-width: 550px) {
  .button-otp {
    padding: 11px;
    font-size: 11px;
  }
}

@media only screen and (max-width: 509px) {
  .form-line label:first-child {
    margin: 0 0 5px 0;
    width: 100%;
  }

  .form-validation-input-with-button {
    flex-direction: column;
    gap: 5px;
  }

  .form-line {
    flex-direction: column;
  }

  .warranty-logo {
    width: 100%;
    height: auto;
  }

  .button-otp {
    padding: 10px;
    font-size: 11px;
  }
}

.entry-methods {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 510px) {
  .form-line label:first-child {
    margin: 0;
    width: 15%;
  }

  .form-line input {
    width: 100%;
  }

  .form-line select {
    width: 100%;
  }
}

.verifyButton {
  color: #000000;
  font-weight: bold;
}
</style>
